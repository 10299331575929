import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import moment from 'moment';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'moment/locale/fr';
import Provider from './component/store/redux/store';

ReactDOM.render(
  <React.StrictMode>
    <Provider>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

if (process.env.REACT_APP_STAGE === 'development') {
  reportWebVitals(console.log);
}
moment.locale('fr');
