export type Error = {
  id: number;
  humanInfo: string;
  message: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  detail?: any;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function newError(humanInfo: string, message: string, detail?: any): Error {
  return {
    id: new Date().getTime(),
    humanInfo,
    message,
    detail,
  };
}
