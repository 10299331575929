import { createAction } from 'typesafe-actions';

export const swInit = createAction('serviceworker/INIT')<Record<string, unknown>>();

export const swInitReset = createAction('serviceworker/INIT_RESET')<Record<string, unknown>>();

export const swUpdate = createAction('serviceworker/UPDATE')<ServiceWorkerRegistration>();

export const swUpdatedReload = createAction('serviceworker/UPDATE_RELOAD')<
  Record<string, unknown>
>();
