import produce from 'immer';

import { createReducer, ActionType } from 'typesafe-actions';
import { CourseWithSnapshot } from '../../module/dao/model';
import * as Actions from './action';
import { State, initialState } from '../../store/redux/state';
import { PromotionsCollectionWithSnapshot } from '../../promotion/dao/model';
import { TeachersCollectionWithSnapshot } from '../../teacher/dao/model';

function genericPaginationNextPage(paginationState: any, firestoreData: any) {
  if (paginationState) {
    const lastDoc = firestoreData.slice(-1)[0];
    // eslint-disable-next-line no-param-reassign
    paginationState.lastDoc = lastDoc;
    paginationState.goBackDocs.push(firestoreData[0]);
    return paginationState;
  }
  return {
    lastDoc: firestoreData.slice(-1)[0],
    goBackDocs: firestoreData.slice(0, 1),
  };
}

function genericPaginationLastPage(paginationState: any) {
  if (paginationState && paginationState.goBackDocs.length > 0) {
    const lastDoc = paginationState.goBackDocs.pop()!;
    return {
      goBackDocs: paginationState.goBackDocs,
      lastDoc,
    };
  }
  return {
    goBackDocs: [],
    lastDoc: {} as CourseWithSnapshot,
  };
}

/**
 * Update the admin filter state.
 *
 * ? (Agile) prio: A, effort: 3, coded
 */
function updateFilterStateCourse(
  state: State,
  { payload }: ActionType<typeof Actions.updateFilterStateCourse>,
) {
  return produce(state, (draft) => {
    draft.admin.filterState.course = payload.label;
  });
}

function updateFilterStateTeacher(
  state: State,
  { payload }: ActionType<typeof Actions.updateFilterStateTeacher>,
) {
  return produce(state, (draft) => {
    draft.admin.filterState.teacher = payload;
  });
}

function updateFilterStatePromo(
  state: State,
  { payload }: ActionType<typeof Actions.updateFilterStatePromo>,
) {
  return produce(state, (draft) => {
    draft.admin.filterState.promo = payload;
  });
}

function updateFilterStateScolarYear(
  state: State,
  { payload }: ActionType<typeof Actions.updateFilterStateScolarYear>,
) {
  return produce(state, (draft) => {
    draft.admin.filterState.scolarYear = payload.label;
  });
}

function updateFilterStateStudent(
  state: State,
  { payload }: ActionType<typeof Actions.updateFilterStateStudent>,
) {
  return produce(state, (draft) => {
    draft.admin.filterState.student = payload;
  });
}

function updateFilterStateReferent(
  state: State,
  { payload }: ActionType<typeof Actions.updateFilterStateReferent>,
) {
  return produce(state, (draft) => {
    draft.admin.filterState.referent = payload;
  });
}

function updateFilterStateModule(
  state: State,
  { payload }: ActionType<typeof Actions.updateFilterStateModule>,
) {
  return produce(state, (draft) => {
    draft.admin.filterState.moduleState = payload.filters;
  });
}

/**
 * Update the admin sorting state.
 *
 * ? (Agile) prio: A, effort: 3, coded
 */
function updateSortingState(
  state: State,
  { payload }: ActionType<typeof Actions.updateSortingState>,
) {
  return produce(state, (draft) => {
    draft.admin.sortingState.type = payload.type;
  });
}

/**
 * Update the admin pagination state.
 *
 * ? (Agile) prio: A, effort: 3, coded
 */

// formsList
function formsListGoNextPageState(
  state: State,
  { payload }: ActionType<typeof Actions.formsListGoNextPageState>,
) {
  return produce(state, (draft) => {
    const formListData = payload.forms;
    if (formListData && formListData.length > 0) {
      const result = genericPaginationNextPage(draft.admin.paginationState.formsList, formListData);
      draft.admin.paginationState.formsList = result;
    }
  });
}

function formsListGoLastPageState(state: State) {
  return produce(state, (draft) => {
    if (draft.admin.paginationState.formsList) {
      const result = genericPaginationLastPage(draft.admin.paginationState.formsList);
      draft.admin.paginationState.formsList = result;
    }
  });
}

function formsListResetPaginationState(state: State) {
  return produce(state, (draft) => {
    draft.admin.paginationState.formsList.goBackDocs = [];
    draft.admin.paginationState.formsList.lastDoc = {} as CourseWithSnapshot;
  });
}

// referentFormsList
function referentFormsListGoNextPageState(
  state: State,
  { payload }: ActionType<typeof Actions.referentFormsListGoNextPageState>,
) {
  return produce(state, (draft) => {
    const formListData = payload.forms;
    if (formListData && formListData.length > 0) {
      const result = genericPaginationNextPage(
        draft.admin.paginationState.referentFormsList,
        formListData,
      );
      draft.admin.paginationState.referentFormsList = result;
    }
  });
}

function referentFormsListGoLastPageState(state: State) {
  return produce(state, (draft) => {
    if (draft.admin.paginationState.referentFormsList) {
      const result = genericPaginationLastPage(draft.admin.paginationState.referentFormsList);
      draft.admin.paginationState.referentFormsList = result;
    }
  });
}

function referentFormsListResetPaginationState(state: State) {
  return produce(state, (draft) => {
    draft.admin.paginationState.referentFormsList.goBackDocs = [];
    draft.admin.paginationState.referentFormsList.lastDoc = {} as CourseWithSnapshot;
  });
}

// promotionsList
function promotionsListGoNextPageState(
  state: State,
  { payload }: ActionType<typeof Actions.promotionsListGoNextPageState>,
) {
  return produce(state, (draft) => {
    const promotionsData = payload;
    if (promotionsData && promotionsData.length > 0) {
      const result = genericPaginationNextPage(
        draft.admin.paginationState.promotionsList,
        promotionsData,
      );
      draft.admin.paginationState.promotionsList = result;
    }
  });
}

function promotionsListGoLastPageState(state: State) {
  return produce(state, (draft) => {
    if (draft.admin.paginationState.promotionsList) {
      const result = genericPaginationLastPage(draft.admin.paginationState.promotionsList);
      draft.admin.paginationState.promotionsList = result;
    }
  });
}

function promotionsListResetPaginationState(state: State) {
  return produce(state, (draft) => {
    draft.admin.paginationState.promotionsList.goBackDocs = [];
    draft.admin.paginationState.promotionsList.lastDoc = {} as PromotionsCollectionWithSnapshot;
  });
}

// teachersList
function teachersListGoNextPageState(
  state: State,
  { payload }: ActionType<typeof Actions.teachersListGoNextPageState>,
) {
  return produce(state, (draft) => {
    const teachersData = payload;
    if (teachersData && teachersData.length > 0) {
      const result = genericPaginationNextPage(
        draft.admin.paginationState.teachersList,
        teachersData,
      );
      draft.admin.paginationState.teachersList = result;
    }
  });
}

function teachersListGoLastPageState(state: State) {
  return produce(state, (draft) => {
    if (draft.admin.paginationState.teachersList) {
      const result = genericPaginationLastPage(draft.admin.paginationState.teachersList);
      draft.admin.paginationState.teachersList = result;
    }
  });
}

function teachersListResetPaginationState(state: State) {
  return produce(state, (draft) => {
    draft.admin.paginationState.teachersList.goBackDocs = [];
    draft.admin.paginationState.teachersList.lastDoc = {} as TeachersCollectionWithSnapshot;
  });
}

const reducer = createReducer(initialState)
  .handleAction(Actions.updateFilterStateCourse, updateFilterStateCourse)
  .handleAction(Actions.updateFilterStateTeacher, updateFilterStateTeacher)
  .handleAction(Actions.updateFilterStatePromo, updateFilterStatePromo)
  .handleAction(Actions.updateFilterStateScolarYear, updateFilterStateScolarYear)
  .handleAction(Actions.updateFilterStateStudent, updateFilterStateStudent)
  .handleAction(Actions.updateFilterStateReferent, updateFilterStateReferent)
  .handleAction(Actions.updateFilterStateModule, updateFilterStateModule)
  .handleAction(Actions.updateSortingState, updateSortingState)
  .handleAction(Actions.formsListGoNextPageState, formsListGoNextPageState)
  .handleAction(Actions.formsListGoLastPageState, formsListGoLastPageState)
  .handleAction(Actions.formsListResetPaginationState, formsListResetPaginationState)
  .handleAction(Actions.referentFormsListGoNextPageState, referentFormsListGoNextPageState)
  .handleAction(Actions.referentFormsListGoLastPageState, referentFormsListGoLastPageState)
  .handleAction(
    Actions.referentFormsListResetPaginationState,
    referentFormsListResetPaginationState,
  )
  .handleAction(Actions.promotionsListGoNextPageState, promotionsListGoNextPageState)
  .handleAction(Actions.promotionsListGoLastPageState, promotionsListGoLastPageState)
  .handleAction(Actions.promotionsListResetPaginationState, promotionsListResetPaginationState)
  .handleAction(Actions.teachersListGoNextPageState, teachersListGoNextPageState)
  .handleAction(Actions.teachersListGoLastPageState, teachersListGoLastPageState)
  .handleAction(Actions.teachersListResetPaginationState, teachersListResetPaginationState);

export default reducer;
