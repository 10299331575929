import { createReducer } from 'typesafe-actions';
import { initialState } from './state';
import Error from '../../error/redux/reducer';
import Survey from '../../submission/redux/reducer';
import Auth from '../../auth/redux/reducer';
import FilterState from '../../boAdmin/redux/reducer';
import SW from '../../serviceworker/redux/reducer';
import Theme from '../../theme/redux/reducer';

const reducer = createReducer(initialState, {
  ...Error.handlers,
  ...Survey.handlers,
  ...Auth.handlers,
  ...FilterState.handlers,
  ...SW.handlers,
  ...Theme.handlers,
});

export default reducer;
