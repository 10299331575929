import { createAction } from 'typesafe-actions';
import { Error } from '../dao/model';

export const pushError = createAction('error/PUSH')<{
  error: Error;
}>();

export const removeError = createAction('error/REMOVE')<{
  id: number;
}>();

export const resetErrors = createAction('error/RESET')<Record<string, unknown>>();

export const showErrorAlert = createAction('error/OPENALERT')<Record<string, unknown>>();

export const closeErrorAlert = createAction('error/CLOSEALERT')<Record<string, unknown>>();
