import { createAction } from 'typesafe-actions';
import { User } from 'firebase/auth';

export const saveCurrentUser = createAction('auth/user/SAVE_CURRENT_USER')<{
  fbUser: User;
  claims: any;
}>();

export const logoutCurrentUser = createAction('auth/user/LOGOUT_CURRENT_USER')<
  Record<string, unknown>
>();
