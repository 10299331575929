import { createTheme } from '@mui/material';
import sdpKoraTheme from '.';

export function createPaletteModeTheme(paletteMode: string) {
  return createTheme(sdpKoraTheme, {
    palette: {
      mode: paletteMode,
      primary: {
        main:
          paletteMode === 'light'
            ? process.env.REACT_APP_PRIMARY_COLOR || '#ef531b'
            : process.env.REACT_APP_DARK_PRIMARY_COLOR || '#ef531b',
      },
      secondary: {
        main:
          paletteMode === 'light'
            ? process.env.REACT_APP_SECONDARY_COLOR || '#304692'
            : process.env.REACT_APP_DARK_SECONDARY_COLOR || '#304692',
      },
      text: {
        primary: paletteMode === 'light' ? '#212121' : '#fff',
        secondary: paletteMode === 'light' ? '#666666' : '#B8B8B8',
        disabled: paletteMode === 'light' ? '#9E9E9E' : '#898989',
      },
      background: {
        default: paletteMode === 'light' ? '#fff' : '#1a1a1a',
        paper: paletteMode === 'light' ? '#fff' : '#1a1a1a',
      },
      action: {
        disabled: 'fff',
      },
    },
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: sdpKoraTheme.palette.grey[700],
          },
        },
      },
    },
  });
}
