import { User as FirebaseUser, getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { User } from './model';
import { getCurrentUser } from './selector';
import firebaseApp from '../../../firebase';
import { logoutCurrentUser, saveCurrentUser } from '../redux/action';

function identicalUserChecker(
  fbUser: FirebaseUser | null,
  fbRoles: string[] | null,
  reduxUser: User | null,
): boolean {
  if (!fbUser || !fbRoles || !reduxUser?.claims.roles) return false;
  if (fbUser.email !== reduxUser.email) return false;
  if (fbRoles.length !== reduxUser.claims.roles.length) return false;
  if (reduxUser.claims.roles.filter((role) => !fbRoles.includes(role)).length > 0) return false;
  return true;
}

/**
 * Retrieve the currently logged in user
 */
export function useGetCurrentUser(): User | null {
  const currentUser = useSelector(getCurrentUser, shallowEqual);

  const dispatch = useDispatch();
  const [fbCurrentUser, initialising, fbAuthError] = useAuthState(getAuth(firebaseApp), {
    onUserChanged: async (fbUser: FirebaseUser | null) => {
      if (fbUser) {
        if (fbUser.email === null || fbUser.email === undefined || fbUser.email === '') {
          dispatch(logoutCurrentUser({}));
          return;
        }
        fbUser
          .getIdTokenResult()
          .then((idTokenResult) => {
            const payload = { fbUser, claims: idTokenResult.claims };
            if (
              !identicalUserChecker(fbUser, idTokenResult.claims.roles as string[], currentUser)
            ) {
              dispatch(saveCurrentUser(payload));
            }
          })
          .catch(() => {
            dispatch(logoutCurrentUser({}));
          });
      }
    },
  });

  if (!initialising && !fbAuthError && fbCurrentUser === null) return null;

  fbCurrentUser
    ?.getIdTokenResult()
    .then((idTokenResult) => {
      const payload = { fbUser: fbCurrentUser, claims: idTokenResult.claims };
      if (
        !identicalUserChecker(fbCurrentUser, idTokenResult.claims.roles as string[], currentUser)
      ) {
        dispatch(saveCurrentUser(payload));
      }
    })
    .catch(() => {
      dispatch(logoutCurrentUser({}));
    });
  return currentUser;
}
