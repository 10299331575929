import produce from 'immer';

import { createReducer, ActionType } from 'typesafe-actions';
import { Map } from 'immutable';
import * as Actions from './action';
import { State, initialState } from '../../store/redux/state';
import { newError } from '../../error/dao/model';
import { pushErrorReducer } from '../../error/redux/reducer';
import { pushError } from '../../error/redux/action';

/**
 * Create a given Survey.
 *
 * ? (Agile) prio: A, effort: 1, coded
 */
function createSurvey(
  state: State,
  { payload: { survey } }: ActionType<typeof Actions.createSurvey>,
) {
  return produce(state, (draft) => {
    const existingSurvey = draft.survey.surveys.get(survey.form.id);
    const answers = (existingSurvey && existingSurvey.answers) || Map();
    draft.survey.surveys = draft.survey.surveys.set(survey.form.id, {
      ...existingSurvey,
      ...survey,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      answers,
    });
  });
}

/**
 * Update a given Survey.
 *
 * ? (Agile) prio: A, effort: 3, coded
 */
function updateAnswerSurvey(
  state: State,
  { payload }: ActionType<typeof Actions.updateAnswerSurvey>,
) {
  const { surveyId, answer } = payload;

  const newState = produce(state, (draft) => {
    const existingSurvey = draft.survey.surveys.get(surveyId);

    if (!existingSurvey) {
      const error = newError(
        "Impossible de prendre en compte votre réponse: l'enquête n'existe pas.",
        'Survey doest not exist in Redux.',
        payload,
      );

      pushErrorReducer(state, pushError({ error }));
    } else {
      draft.survey.surveys = draft.survey.surveys.set(surveyId, {
        ...existingSurvey,
        answers: existingSurvey.answers.set(answer.questionId, answer),
      });
    }
  });

  return newState;
}

/**
 * Mark a survey as sent.
 *
 * ? (Agile) prio: A, effort: unseen, coded
 */
function markSurveyAsSent(
  state: State,
  { payload: { surveyId, docRefId } }: ActionType<typeof Actions.markAsSent>,
) {
  return produce(state, (draft) => {
    const existingSurvey = draft.survey.surveys.get(surveyId);

    if (!existingSurvey) {
      const error = newError(
        "Impossible de marquer cette enquête comme envoyée : l'enquête n'existe pas.",
        'Survey doest not exist in Redux.',
        { surveyId },
      );

      pushErrorReducer(state, pushError({ error }));
    } else {
      draft.survey.surveys = draft.survey.surveys.set(surveyId, {
        ...existingSurvey,
        sentAtTimestamp: new Date().getTime(),
        docRefId,
      });
    }
  });
}

const reducer = createReducer(initialState)
  .handleAction(Actions.createSurvey, createSurvey)
  .handleAction(Actions.updateAnswerSurvey, updateAnswerSurvey)
  .handleAction(Actions.markAsSent, markSurveyAsSent);

export default reducer;
