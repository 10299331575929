import React from 'react';
import { Link } from 'react-router-dom';
import './home.scss';
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Button,
  Typography,
  CardActions,
  Container,
} from '@mui/material';

export default function Home() {
  return (
    <Container className="App">
      <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography component="h1" variant="h5" color="primary">
            KORA - Saisie des notes et feedbacks des enseignements -{' '}
            {process.env.REACT_APP_SCHOOL_NAME}
          </Typography>
          <Typography component="h2" variant="h5" color="secondary">
            {process.env.REACT_APP_SCHOOL_NAME
              ? process.env.REACT_APP_SCHOOL_NAME.toUpperCase()
              : ''}
          </Typography>
        </Grid>
        <Grid item sm>
          <Card>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="Photo d'étudiants"
                width="328"
                height="250"
                image="/img/etudiants.jpeg"
                title="Accès étudiants"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Étudiants
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Accédez en temps réel aux notes saisies
                  <br />
                  et validées par vos intervenants.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Link to="/login">
                <Button
                  size="large"
                  variant="contained"
                  color="secondary"
                  id="homeLoginStudentSpaceBtn">
                  Accéder à mon espace étudiant
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>

        <Grid item sm>
          <Card>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="Photo d'un intervenant"
                width="328"
                height="250"
                image="/img/enseignants.jpeg"
                title="Accès intervenants"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Intervenants
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Accédez à la liste de vos modules pour découvrir les avis des étudiants
                  <br />
                  et saisir leurs résultats.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Link to="/login">
                <Button
                  size="large"
                  variant="contained"
                  color="secondary"
                  id="homeLoginTeacherSpaceBtn">
                  Accéder à mon espace intervenant
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
