import { CourseWithSnapshot } from '../../module/dao/model';
import { PromotionsCollectionWithSnapshot } from '../../promotion/dao/model';
import { TeachersCollectionWithSnapshot } from '../../teacher/dao/model';

export const initialState = {
  filterState: {
    user: {
      id: '' as string,
      label: '' as string,
    },
    course: '' as string,
    teacher: {
      id: '' as string,
      label: '' as string,
    },
    promo: {
      id: '' as string,
      label: '' as string,
    },
    scolarYear: '' as string,
    student: {
      id: '' as string,
      label: '' as string,
    },
    referent: {
      id: '' as string,
      label: '' as string,
    },
    moduleState: [''] as string[],
  },
  sortingState: {
    type: 'promo' as string,
  },
  paginationState: {
    formsList: {
      goBackDocs: [] as Array<CourseWithSnapshot>,
      lastDoc: {} as CourseWithSnapshot,
    },
    referentFormsList: {
      goBackDocs: [] as Array<CourseWithSnapshot>,
      lastDoc: {} as CourseWithSnapshot,
    },
    teachersList: {
      goBackDocs: [] as Array<TeachersCollectionWithSnapshot>,
      lastDoc: {} as TeachersCollectionWithSnapshot,
    },
    promotionsList: {
      goBackDocs: [] as Array<PromotionsCollectionWithSnapshot>,
      lastDoc: {} as PromotionsCollectionWithSnapshot,
    },
  },
};
