import produce from 'immer';

import { createReducer, ActionType } from 'typesafe-actions';
import { signOut } from 'firebase/auth';
import { State, initialState } from '../../store/redux/state';
import * as Actions from './action';
import { newError } from '../../error/dao/model';
import { pushErrorReducer } from '../../error/redux/reducer';
import { pushError } from '../../error/redux/action';
import { auth } from '../../../firebase';
import { User } from '../dao/model';

/**
 * Save a given User as the Current User
 *
 * ? (Agile) prio: A, effort: 3, coded
 */
function saveCurrentUser(
  state: State,
  { payload: { fbUser, claims } }: ActionType<typeof Actions.saveCurrentUser>,
) {
  return produce(state, (draft) => {
    if (!fbUser) {
      const error = newError('Identification invalide.', 'User is empty.', fbUser);
      pushErrorReducer(state, pushError({ error }));
    } else if (!fbUser.email) {
      const error = newError('Identification invalide.', 'User email is empty.', fbUser);
      pushErrorReducer(state, pushError({ error }));
    } else {
      const user: User = { email: fbUser.email, id: fbUser.uid, claims };
      draft.auth.currentUser = user;
    }
  });
}

/**
 * Logout a given User as the Current User
 *
 * ? (Agile) prio: A, effort: unseen, coded
 */
function logoutCurrentUser(state: State, _: ActionType<typeof Actions.logoutCurrentUser>) {
  signOut(auth).catch((error) => console.error('FAILED Firebase Logout : ', error));
  return produce(state, (draft) => {
    draft.auth.currentUser = null;
  });
}

const reducer = createReducer(initialState)
  .handleAction(Actions.logoutCurrentUser, logoutCurrentUser)
  .handleAction(Actions.saveCurrentUser, saveCurrentUser);

export default reducer;
