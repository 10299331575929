import produce from 'immer';

import { createReducer, ActionType } from 'typesafe-actions';
import * as Actions from './action';
import { State, initialState } from '../../store/redux/state';

export function pushErrorReducer(
  state: State,
  { payload: { error } }: ActionType<typeof Actions.pushError>,
) {
  console.error(`${error.humanInfo} : ${error.message} ${error.detail}`);

  return produce(state, (draft) => {
    draft.error.errors.set(error.id, error);
    draft.error.isOpenErrorAlert = true;
  });
}

function removeError(state: State, { payload: { id } }: ActionType<typeof Actions.removeError>) {
  return produce(state, (draft) => {
    draft.error.errors.delete(id);
  });
}

function resetErrors(state: State, _: ActionType<typeof Actions.resetErrors>) {
  return produce(state, (draft) => {
    draft.error.errors.clear();
  });
}

function showErrorAlert(state: State, _: ActionType<typeof Actions.showErrorAlert>) {
  return produce(state, (draft) => {
    draft.error.isOpenErrorAlert = true;
  });
}

function closeErrorAlert(state: State, _: ActionType<typeof Actions.closeErrorAlert>) {
  return produce(state, (draft) => {
    draft.error.isOpenErrorAlert = false;
  });
}

const reducer = createReducer(initialState)
  .handleAction(Actions.pushError, pushErrorReducer)
  .handleAction(Actions.removeError, removeError)
  .handleAction(Actions.resetErrors, resetErrors)
  .handleAction(Actions.showErrorAlert, showErrorAlert)
  .handleAction(Actions.closeErrorAlert, closeErrorAlert);

export default reducer;
