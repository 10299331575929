// Google
export const GOOGLE_SERVER_LOCATION = 'europe-west1';

// Storage Buckets
export const STORAGE_BUCKET_EXPORTS = 'csv-export';

// Storage Folders
export const STORAGE_FOLDER_EXPORTS = 'exports';
export const STORAGE_FOLDER_MODELS = 'models';
export const STORAGE_FOLDER_ARCHIVES = 'archives';

// Storage Files Name
export const STORAGE_FILE_COURSES = 'export_courses_kora.csv';
export const STORAGE_FILE_SUBMISSIONS = 'export_submissions_kora.csv';
export const STORAGE_FILE_PROMOTIONS = 'export_promotions_kora.csv';
export const STORAGE_FILE_TEACHERS = 'export_teachers_kora.csv';
export const STORAGE_FILE_COURSE_LABEL = 'export_course_label_kora.csv';
export const STORAGE_FILE_UEP = 'export_uep_kora.csv';
export const STORAGE_FILE_UNIFIED_COURSE_LABEL = 'export_unified_course_label_kora.csv';
export const STORAGE_FILE_STUDENTS = 'export_students_kora.csv';
export const STORAGE_FILE_GRADES = 'export_grades_kora.csv';
export const STORAGE_FILE_UNIFIED_MEAN = 'export_unified_means_kora.csv';
export const STORAGE_FILE_SCOLAR_YEAR = 'export_scolar_year_kora.csv';

// Firestore Paths
export const FIRESTORE_COLLECTION_COURSE = 'course';
export const FIRESTORE_COLLECTION_PROMOTION = 'promotion';
export const FIRESTORE_COLLECTION_TEACHER = 'teacher';
export const FIRESTORE_COLLECTION_CONFIGURATION = 'configuration';
export const FIRESTORE_COLLECTION_STUDENT = 'student';
export const FIRESTORE_COLLECTION_SUBMISSION = 'submission';
export const FIRESTORE_COLLECTION_ASSESSMENT = 'assessment';

// Firestore
export const PAGE_SIZE = 20;

// Time periods
export const PERIOD_ONE_DAY = 24 * 60 * 60 * 1000;

// Functions
export const HTTPS_CALLABLE = `https://${process.env.REACT_APP_FIREBASE_HTTPS_CALLABLE}`;

// Assessments
export const TITLE_MAX_LENGHT = 20;
