import React from 'react';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AdminLayout from '../layout/AdminLayout';

export default function LazyLoadingIndicator() {
  return (
    <AdminLayout layoutMaxWidth="xl">
      <AutorenewIcon color="secondary" className="rotating" />
      Loading View…
    </AdminLayout>
  );
}
