/* eslint-disable no-console */
import {
  connectFirestoreEmulator,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from 'firebase/firestore';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { GOOGLE_SERVER_LOCATION } from './lib/constants.alias';

declare global {
  // eslint-disable-next-line vars-on-top, no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}

const IS_EMULATOR_ON = true;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_GCP_API_KEY,
  authDomain: process.env.REACT_APP_GCP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_GCP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_GCP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_GCP_MESSAGE_SEND_ID,
  appId: process.env.REACT_APP_GCP_APP_ID,
  measurementId: process.env.REACT_APP_GCP_MEASURMENT_ID,
};

const firebaseApp: FirebaseApp = initializeApp(firebaseConfig);
const firestore = initializeFirestore(firebaseApp, {
  localCache: persistentLocalCache(/* settings */ { tabManager: persistentMultipleTabManager() }),
});
const storage = getStorage(firebaseApp);
const functions = getFunctions(firebaseApp, GOOGLE_SERVER_LOCATION);
const auth = getAuth(firebaseApp);

// APP environment
if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
  console.log('💻 LOCAL Environment');
  document.title = '💻 '.concat(document.title);
} else if (process.env.REACT_APP_STAGE === 'production') {
  console.log('🚨 PRODUCTION Environment');
} else {
  console.log('🧪 STAGING Environment');
  document.title = '🧪 '.concat(document.title);
}

// App Check overrides
if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
  console.log('AppCheck - Localhost detected');
  global.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_LOCALHOST_APP_CHECK_TOKEN;
}

if (process.env.REACT_APP_CI_IS_ACTIVE === 'true') {
  console.log('AppCheck - CI detected');
  global.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_CI_APP_CHECK_TOKEN;
}

if (process.env.REACT_APP_GCP_RECAPTCHA_PUBLIC_KEY) {
  initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider(process.env.REACT_APP_GCP_RECAPTCHA_PUBLIC_KEY),
    isTokenAutoRefreshEnabled: true,
  });
}

if (
  IS_EMULATOR_ON &&
  (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1')
) {
  console.log('Connecting to Firebase emulator components...');
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFirestoreEmulator(firestore, 'localhost', 8080);
  connectStorageEmulator(storage, 'localhost', 9199);
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

export const appMode = process.env.REACT_APP_STAGE;
export { firestore, storage, functions, auth };
export default firebaseApp;
