import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import Router from './Router';
import './App.css';
import SWAlerts from './component/serviceworker/SWAlerts';
import { useGetPaletteMode } from './component/theme/dao/dao';
import { createPaletteModeTheme } from './lib/theme/paletteModeTheme';

export default function App() {
  const appPaletteMode = useGetPaletteMode();

  const theme = React.useMemo(() => createPaletteModeTheme(appPaletteMode), [appPaletteMode]);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={5}>
        <Router />
      </SnackbarProvider>
      <SWAlerts />
    </ThemeProvider>
  );
}
