import React from 'react';
import { ListItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import { Error } from './dao/model';
import { removeError } from './redux/action';

type Props = {
  error: Error;
};

export default function ErrorDisplay({ error: { humanInfo, id } }: Props) {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(removeError({ id }));
  };
  return (
    <ListItem key={id} className="error">
      {humanInfo}
      <DeleteIcon color="secondary" onClick={handleClick} />
    </ListItem>
  );
}
