import { shallowEqual, useSelector } from 'react-redux';
import { getTheme, getPaletteMode } from './selector';

export function useGetTheme() {
  return useSelector(getTheme, shallowEqual);
}

export function useGetPaletteMode() {
  return useSelector(getPaletteMode, shallowEqual);
}
