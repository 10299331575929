import * as React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import Brightness3Icon from '@mui/icons-material/Brightness3';
import { useGetPaletteMode } from './dao/dao';
import { updatePaletteMode } from './redux/action';

const useStyles = makeStyles(() => ({
  iconColor: {
    color: 'white',
  },
}));

export default function PaletteModeToggle() {
  const dispatch = useDispatch();
  const currentPaletteMode = useGetPaletteMode();
  const classes = useStyles();

  const paletteModeHandler = () => {
    dispatch(updatePaletteMode());
  };

  return (
    <IconButton sx={{ ml: 1 }} onClick={paletteModeHandler}>
      {currentPaletteMode === 'dark' ? (
        <LightModeIcon className={classes.iconColor} />
      ) : (
        <Brightness3Icon className={classes.iconColor} />
      )}
    </IconButton>
  );
}
