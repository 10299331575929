import produce from 'immer';

import { createReducer, ActionType } from 'typesafe-actions';

import { State, initialState } from '../../store/redux/state';
import * as Actions from './action';

function updatePaletteMode(state: State, _: ActionType<typeof Actions.updatePaletteMode>) {
  const newPaletteModeState = state.theme.paletteMode === 'light' ? 'dark' : 'light';
  return produce(state, (draft) => {
    draft.theme.paletteMode = newPaletteModeState;
  });
}

const reducer = createReducer(initialState).handleAction(
  Actions.updatePaletteMode,
  updatePaletteMode,
);

export default reducer;
