import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
  Container,
  Divider,
  Typography,
  Drawer,
  CssBaseline,
  Box,
  AppBar,
  Toolbar,
  List,
  IconButton,
  Link as HtmlLink,
  ListItem,
  ListItemIcon,
  ListItemText,
  StyledEngineProvider,
  Tooltip,
} from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import LabelIcon from '@mui/icons-material/Label';
import PersonIcon from '@mui/icons-material/Person';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import ListAltIcon from '@mui/icons-material/ListAlt';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import './AdminLayout.scss';
import { Errors } from '../error/Errors';
import { useGetCurrentUser } from '../auth/dao/dao';
import { CheckIsAdmin, CheckIsReferent, CheckIsStudent, CheckIsSuperAdmin } from '../auth/dao/acl';
import packageInfo from '../../package.alias.json';
import PaletteModeToggle from '../theme/PaletteModeToggle';

type Props = {
  children: ReactNode;
  layoutMaxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
};

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <HtmlLink color="inherit" href="https://www.lkn-design.com" target="_blank">
        LK&apos;n Design
      </HtmlLink>{' '}
      {new Date().getFullYear()} - v{packageInfo.version}.
    </Typography>
  );
}

const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.primary.main,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    zIndex: theme.zIndex.drawer,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  textColor: {
    color: 'white',
  },
  appBarColor: {
    color: theme.palette.mode === 'light' ? theme.palette.grey[800] : 'white',
  },
}));

/**
 * Layout of the Admin section.
 *
 * ? (Agile) prio: B, effort: 5
 */
export default function AdminLayout(props: Props) {
  const currentUser = useGetCurrentUser();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const { children, layoutMaxWidth } = props;

  const isAdminUser = CheckIsAdmin(currentUser);
  const isReferentUser = CheckIsReferent(currentUser);
  const isStudentUser = CheckIsStudent(currentUser);
  const isSuperAdminUser = CheckIsSuperAdmin(currentUser);

  return (
    <StyledEngineProvider injectFirst>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={clsx(classes.toolbar, classes.appBarColor)}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
              <MenuIcon className={classes.textColor} />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={clsx(classes.title, classes.textColor)}>
              KORA - Saisie des notes et feedback des enseignements -{' '}
              {process.env.REACT_APP_SCHOOL_NAME}
            </Typography>
            <PaletteModeToggle />
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}>
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon className={classes.appBarColor} />
            </IconButton>
          </div>
          <Divider />
          <List>
            <Link to="/profile" id="linkToProfile">
              <ListItem button>
                <ListItemIcon>
                  <AccountCircleIcon className={classes.appBarColor} />
                </ListItemIcon>
                <ListItemText primary="Identification" className={classes.appBarColor} />
              </ListItem>
            </Link>
            {isAdminUser && (
              <>
                <Link to="/settings">
                  <ListItem button>
                    <ListItemIcon>
                      <SettingsIcon className={classes.appBarColor} />
                    </ListItemIcon>
                    <ListItemText primary="Paramètres" className={classes.appBarColor} />
                  </ListItem>
                </Link>
                <Link to="/importer">
                  <ListItem button>
                    <ListItemIcon>
                      <FileUploadIcon className={classes.appBarColor} />
                    </ListItemIcon>
                    <ListItemText primary="Importeurs" className={classes.appBarColor} />
                  </ListItem>
                </Link>
                <Link to="/exporter">
                  <ListItem button>
                    <ListItemIcon>
                      <FileDownloadIcon className={classes.appBarColor} />
                    </ListItemIcon>
                    <ListItemText primary="Exporteurs" className={classes.appBarColor} />
                  </ListItem>
                </Link>
              </>
            )}
          </List>
          <Divider />
          {isStudentUser ? (
            <List>
              <Link to="/student/grades/list">
                <ListItem button>
                  <ListItemIcon>
                    <ListAltIcon className={classes.appBarColor} />
                  </ListItemIcon>
                  <ListItemText primary="Liste des notes" className={classes.appBarColor} />
                </ListItem>
              </Link>
            </List>
          ) : (
            <List>
              <Link to="/admin/form/list">
                <ListItem button>
                  <ListItemIcon>
                    <ListAltIcon className={classes.appBarColor} />
                  </ListItemIcon>
                  <ListItemText primary="Liste des modules" className={classes.appBarColor} />
                </ListItem>
              </Link>
            </List>
          )}
          <Divider />
          {isAdminUser && (
            <>
              <List>
                <Link to="/admin/scolaryears/list">
                  <ListItem button>
                    <ListItemIcon>
                      <CalendarMonthIcon className={classes.appBarColor} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Gestion années scolaires"
                      className={classes.appBarColor}
                    />
                  </ListItem>
                </Link>
                <Link to="/admin/pedagogicalTeachingUnits/list">
                  <ListItem button>
                    <ListItemIcon>
                      <BookmarksIcon className={classes.appBarColor} />
                    </ListItemIcon>
                    <ListItemText primary="Gestion des UEP" className={classes.appBarColor} />
                  </ListItem>
                </Link>
                <Link to="/admin/unifiedCourses/list">
                  <ListItem button>
                    <ListItemIcon>
                      <LabelIcon className={classes.appBarColor} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Gestion des labels unifiés"
                      className={classes.appBarColor}
                    />
                  </ListItem>
                </Link>
                <Link to="/admin/courses/list">
                  <ListItem button>
                    <ListItemIcon>
                      <LabelImportantIcon className={classes.appBarColor} />
                    </ListItemIcon>
                    <ListItemText primary="Gestion des labels" className={classes.appBarColor} />
                  </ListItem>
                </Link>
                <Link to="/admin/teachers/list">
                  <ListItem button>
                    <ListItemIcon>
                      <PersonIcon className={classes.appBarColor} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Gestion des intervenants"
                      className={classes.appBarColor}
                    />
                  </ListItem>
                </Link>
                <Link to="/admin/promos/list">
                  <ListItem button>
                    <ListItemIcon>
                      <SupervisorAccountIcon className={classes.appBarColor} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Gestion des promotions"
                      className={classes.appBarColor}
                    />
                  </ListItem>
                </Link>
                <Link to="/admin/students/list">
                  <ListItem button>
                    <ListItemIcon>
                      <Tooltip title="Etudiants">
                        <SchoolIcon className={classes.appBarColor} />
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText primary="Gestion des étudiants" className={classes.appBarColor} />
                  </ListItem>
                </Link>
              </List>
              <Divider />
              <List>
                <Link to="/admin/dashboard/promotion">
                  <ListItem button>
                    <ListItemIcon>
                      <TroubleshootIcon className={classes.appBarColor} />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard Promotion" className={classes.appBarColor} />
                  </ListItem>
                </Link>
              </List>
            </>
          )}
          {isReferentUser && (
            <>
              <Divider />
              <List>
                <Link to="/referents/form/list">
                  <ListItem button>
                    <ListItemIcon>
                      <RecentActorsIcon className={classes.appBarColor} />
                    </ListItemIcon>
                    <ListItemText primary="Vue coordinateurs" className={classes.appBarColor} />
                  </ListItem>
                </Link>
              </List>
              <Divider />
              <List>
                <Link to="/admin/dashboard/promotion">
                  <ListItem button>
                    <ListItemIcon>
                      <TroubleshootIcon className={classes.appBarColor} />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard Promotion" className={classes.appBarColor} />
                  </ListItem>
                </Link>
              </List>
            </>
          )}
          {isSuperAdminUser && (
            <>
              <Divider />
              <List>
                <Link to="/superAdmin/adminList" id="iconMenuAdminList">
                  <ListItem>
                    <ListItemIcon>
                      <AdminPanelSettingsIcon className={classes.appBarColor} />
                    </ListItemIcon>
                    <ListItemText primary="Gestion Admins" className={classes.appBarColor} />
                  </ListItem>
                </Link>
                <Link to="/superAdmin/userInfo" id="iconMenuUserInfo">
                  <ListItem>
                    <ListItemIcon>
                      <GroupAddIcon className={classes.appBarColor} />
                    </ListItemIcon>
                    <ListItemText primary="Gestion Utilisateurs" className={classes.appBarColor} />
                  </ListItem>
                </Link>
              </List>
            </>
          )}
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth={layoutMaxWidth} className={classes.container}>
            <Errors />
            {children}
            <Box pt={4}>
              <Copyright />
            </Box>
          </Container>
        </main>
      </div>
    </StyledEngineProvider>
  );
}
