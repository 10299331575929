import { createAction } from 'typesafe-actions';
import { Survey, SurveyId } from '../dao/model';
import { FormAnswer } from '../formAnswer/dao/model';

export const createSurvey = createAction('survey/CREATE')<{
  survey: Survey;
}>();

export const updateAnswerSurvey = createAction('survey/UPDATE_ANSWER')<{
  surveyId: SurveyId;
  answer: FormAnswer;
}>();

export const markAsSent = createAction('survey/MARK_AS_SENT')<{
  surveyId: SurveyId;
  docRefId: string;
}>();
