import { initialState as AdminInitialState } from '../../boAdmin/redux/state';
import { initialState as SurveyInitialState } from '../../submission/redux/state';
import { initialState as ErrorState } from '../../error/redux/state';
import { initialState as AuthInitialState } from '../../auth/redux/state';
import { initialState as ServiceWorkerState } from '../../serviceworker/redux/state';
import { initialState as ThemeInitialState } from '../../theme/redux/state';

export const initialState = {
  /** Indicates that an asynchronuous task is on-going */
  loading: false,
  admin: AdminInitialState,
  survey: SurveyInitialState,
  /** Indicates that some errors occured */
  error: ErrorState,
  auth: AuthInitialState,
  sw: ServiceWorkerState,
  /** Indicates if darkmode is dark or light */
  theme: ThemeInitialState,
};

export type State = typeof initialState;

export const getState = (state: State) => state;
