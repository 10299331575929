import { CourseWithSnapshot } from '../../module/dao/model';
import { User } from './model';

export function CheckIsUndefined(currentUser: User | null) {
  return currentUser && undefined === currentUser.claims.roles;
}

export function CheckIsLoggedIn(currentUser: User | null) {
  return undefined !== currentUser && currentUser !== null;
}

export function CheckIsTeacher(currentUser: User | null) {
  return currentUser
    ? undefined !== currentUser.claims.roles && currentUser.claims.roles.includes('teacher')
    : false;
}

export function CheckIsReferent(currentUser: User | null) {
  return currentUser
    ? undefined !== currentUser.claims.roles && currentUser.claims.roles.includes('referent')
    : false;
}

export function CheckIsAdmin(currentUser: User | null): boolean {
  return currentUser
    ? undefined !== currentUser.claims.roles && currentUser.claims.roles.includes('admin')
    : false;
}

export function CheckIsSuperAdmin(currentUser: User | null): boolean {
  return currentUser
    ? undefined !== currentUser.claims.roles && currentUser.claims.roles.includes('superAdmin')
    : false;
}

export function CheckIsStudent(currentUser: User | null): boolean {
  return currentUser
    ? undefined !== currentUser.claims.roles && currentUser.claims.roles.includes('student')
    : false;
}

export function CheckTeacherIdToAccessRessource(
  currentUser: User | null,
  courseInfo: CourseWithSnapshot | undefined,
): boolean {
  if (!currentUser || !courseInfo) return false;

  return courseInfo.teacher.id === currentUser.id;
}

export function CheckReferentIdToAccessRessource(
  currentUser: User | null,
  courseInfo: CourseWithSnapshot | undefined,
): boolean {
  if (!currentUser || !courseInfo) return false;

  const { referentIds } = courseInfo;
  if (!referentIds) return false;

  const referent = referentIds.find((referentId) => currentUser.id === referentId);

  return !!referent;
}
