import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  List,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Alert,
  AlertTitle,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { getErrors, getErrorAlertOpenedState } from './dao/selector';
import ErrorDisplay from './ErrorDisplay';
import { resetErrors, closeErrorAlert, pushError } from './redux/action';
import { Error } from './dao/model';
import AdminLayout from '../layout/AdminLayout';

export function Errors() {
  const errors = useSelector(getErrors, shallowEqual);
  const dispatch = useDispatch();

  const openErrorAlert = useSelector(getErrorAlertOpenedState);

  const handleClose = () => {
    dispatch(closeErrorAlert({}));
  };

  const handleReset = () => {
    dispatch(resetErrors({}));
  };

  if (errors.length > 0) {
    return (
      <>
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={openErrorAlert}
          onClose={handleClose}
          className="errors">
          <DialogTitle id="simple-dialog-title">
            {errors.length === 1 ? 'Une erreur est survenue :' : 'Des erreurs sont survenues :'}
          </DialogTitle>

          <DialogContent>
            <List>
              {errors.map((error) => (
                <ErrorDisplay error={error} key={error.id} />
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleReset} color="primary">
              Tout effacer
              <ClearIcon color="primary" fontSize="large" />
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  return <></>;
}

export function GenerateErrorWithLayout(error: Error) {
  const dispatch = useDispatch();
  dispatch(pushError({ error }));

  const { humanInfo, message, detail } = error;
  return (
    <AdminLayout layoutMaxWidth="xl">
      <Grid container spacing={3} direction="row" justifyContent="center">
        <Grid item xs={9}>
          <Alert severity="error">
            <AlertTitle>
              {humanInfo} :<br /> {message}
            </AlertTitle>
            {JSON.stringify(detail, null, 2)}
          </Alert>
        </Grid>
      </Grid>
    </AdminLayout>
  );
}

export function GenerateError(error: Error) {
  const dispatch = useDispatch();
  dispatch(pushError({ error }));

  const { humanInfo, message, detail } = error;
  return (
    <Grid container spacing={3} direction="row" justifyContent="center">
      <Grid item xs={9}>
        <Alert severity="error">
          <AlertTitle>
            {humanInfo} :<br /> {message}
          </AlertTitle>
          {JSON.stringify(detail, null, 2)}
        </Alert>
      </Grid>
    </Grid>
  );
}
