import { createSelector } from 'reselect';
import { getState } from '../../store/redux/state';

export const getErrorMap = createSelector([getState], (state) => state.error.errors);

export const getErrors = createSelector([getErrorMap], (state) => Array.from(state.values()));

export const getErrorAlertOpenedState = createSelector(
  [getState],
  (state) => state.error.isOpenErrorAlert,
);
