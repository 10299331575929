import produce from 'immer';

import { createReducer, ActionType } from 'typesafe-actions';
import * as Actions from './action';
import { State, initialState } from '../../store/redux/state';

export function setServiceWorkerInitialized(
  state: State,
  { payload: _ }: ActionType<typeof Actions.swInit>,
) {
  return produce(state, (draft) => {
    draft.sw.serviceWorkerInitialized = true;
  });
}

export function setServiceWorkerInitializedReset(
  state: State,
  { payload: _ }: ActionType<typeof Actions.swInit>,
) {
  return produce(state, (draft) => {
    draft.sw.serviceWorkerInitialized = false;
  });
}

function setServiceWorkerUpdated(
  state: State,
  { payload: reg }: ActionType<typeof Actions.swUpdate>,
) {
  return produce(state, (draft) => {
    draft.sw.serviceWorkerUpdated = true;
    draft.sw.serviceWorkerRegistration = reg;
  });
}

function setServiceWorkerUpdatedReload(
  state: State,
  { payload: _ }: ActionType<typeof Actions.swUpdatedReload>,
) {
  return produce(state, (draft) => {
    const registrationWaiting = draft.sw.serviceWorkerRegistration.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
      registrationWaiting.addEventListener('statechange', (e) => {
        const sw = e && e.target ? (e.target as ServiceWorker) : null;
        if (sw?.state === 'activated') {
          window.location.reload();
        }
      });
    }
    return draft;
  });
}

const reducer = createReducer(initialState)
  .handleAction(Actions.swInit, setServiceWorkerInitialized)
  .handleAction(Actions.swInitReset, setServiceWorkerInitializedReset)
  .handleAction(Actions.swUpdate, setServiceWorkerUpdated)
  .handleAction(Actions.swUpdatedReload, setServiceWorkerUpdatedReload);

export default reducer;
