import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Snackbar, Alert, AlertTitle } from '@mui/material';
import { getServiceWorkerInitialized, getServiceWorkerUpdated } from './dao/selector';
import { swInitReset, swUpdatedReload } from './redux/action';

export default function SWAlerts() {
  const swInitState = useSelector(getServiceWorkerInitialized);
  const swUpdated = useSelector(getServiceWorkerUpdated);
  const dispatch = useDispatch();

  const handleSnackbarClose = () => {
    dispatch(swInitReset({}));
  };
  const handleSWUpdateAlertButton = () => {
    dispatch(swUpdatedReload({}));
  };

  return (
    <>
      <Snackbar open={swInitState} autoHideDuration={5000} onClose={handleSnackbarClose}>
        <Alert severity="success">
          <AlertTitle>KORA</AlertTitle>
          L&apos;application est chargée et mise en cache
        </Alert>
      </Snackbar>
      <Snackbar open={swUpdated} onClose={handleSWUpdateAlertButton}>
        <Alert
          severity="warning"
          action={
            <Button color="inherit" size="small" onClick={handleSWUpdateAlertButton}>
              Recharger
            </Button>
          }>
          <AlertTitle>Votre attention est requise</AlertTitle>
          Une nouvelle version de KORA est disponible, rechargez la page pour en bénéficier.
        </Alert>
      </Snackbar>
    </>
  );
}
