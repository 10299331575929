import React, { ReactNode } from 'react';
import { createStore, applyMiddleware, compose, Store } from 'redux';
import immutableTransform from 'redux-persist-transform-immutable';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { composeWithDevTools } from 'redux-devtools-extension';
import { enableMapSet } from 'immer';
import reducer from './reducer';
import { State } from './state';

const middlewares = [];

enableMapSet();

/* eslint-disable */
if ('development' === process.env.NODE_ENV) {
  const { createLogger } = require(`redux-logger`);

  const logger = createLogger({
    collapsed: (_getState: any, _action: any, logEntry: any) => !logEntry.error,
  });

  middlewares.push(logger);
}
/* eslint-enable */

const persistConfig = {
  key: 's2p-speaker-evaluations-forms',
  storage,
  whitelist: [
    'survey',
    'auth',
    'theme',
    // 'admin', // TODO
  ],
  transforms: [immutableTransform()],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = compose(composeWithDevTools(applyMiddleware(...middlewares)))(createStore)(
  persistedReducer,
) as Store<State>;

const persistor = persistStore(store);

export default function StoreProvider({ children }: { children: ReactNode }) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
}
