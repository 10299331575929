import { createAction } from 'typesafe-actions';
import { CourseWithSnapshot } from '../../module/dao/model';
import { PromotionsCollectionWithSnapshot } from '../../promotion/dao/model';
import { TeachersCollectionWithSnapshot } from '../../teacher/dao/model';

// Filter
export const updateFilterStateCourse = createAction('filter/UPDATE_FILTER_STATE_COURSE')<{
  label: string;
}>();

export const updateFilterStateTeacher = createAction('filter/UPDATE_FILTER_STATE_TEACHER')<{
  id: string;
  label: string;
}>();

export const updateFilterStatePromo = createAction('filter/UPDATE_FILTER_STATE_PROMO')<{
  id: string;
  label: string;
}>();

export const updateFilterStateScolarYear = createAction('filter/UPDATE_FILTER_STATE_SCOLAR_YEAR')<{
  label: string;
}>();

export const updateFilterStateStudent = createAction('filter/UPDATE_FILTER_STATE_STUDENT')<{
  id: string;
  label: string;
}>();

export const updateFilterStateReferent = createAction('filter/UPDATE_FILTER_STATE_REFERENT')<{
  id: string;
  label: string;
}>();

export const updateFilterStateModule = createAction('filter/UPDATE_FILTER_STATE_MODULE')<{
  filters: string[];
}>();

export const updateFilterIndex = createAction('filter/UPDATE_FILTER_INDEX')<{
  filterIndex: string;
}>();

// Sorting
export const updateSortingState = createAction('sorting/UPDATE_SORTING_STATE_')<{
  type: string;
}>();

// Pagination
export const formsListGoNextPageState = createAction('pagination/FORMS_LIST_GO_NEXT_PAGE_STATE')<{
  forms: ReadonlyArray<CourseWithSnapshot>;
}>();
export const formsListGoLastPageState = createAction('pagination/FORMS_LIST_GO_LAST_PAGE_STATE')();
export const formsListResetPaginationState = createAction(
  'pagination/FORMS_LIST_RESET_PAGINATION_STATE',
)();

export const referentFormsListGoNextPageState = createAction(
  'pagination/REFERENT_FORMS_LIST_GO_NEXT_PAGE_STATE',
)<{
  forms: ReadonlyArray<CourseWithSnapshot>;
}>();
export const referentFormsListGoLastPageState = createAction(
  'pagination/REFERENT_FORMS_LIST_GO_LAST_PAGE_STATE',
)();
export const referentFormsListResetPaginationState = createAction(
  'pagination/REFERENT_FORMS_LIST_RESET_PAGINATION_STATE',
)();

export const promotionsListGoNextPageState = createAction(
  'pagination/PROMOTIONS_LIST_GO_NEXT_PAGE_STATE',
)<PromotionsCollectionWithSnapshot[]>();
export const promotionsListGoLastPageState = createAction(
  'pagination/PROMOTIONS_LIST_GO_LAST_PAGE_STATE',
)();
export const promotionsListResetPaginationState = createAction(
  'pagination/PROMOTIONS_LIST_RESET_PAGINATION_STATE',
)();

export const teachersListGoNextPageState = createAction(
  'pagination/TEACHERS_LIST_GO_NEXT_PAGE_STATE',
)<TeachersCollectionWithSnapshot[]>();
export const teachersListGoLastPageState = createAction(
  'pagination/TEACHERS_LIST_GO_LAST_PAGE_STATE',
)();
export const teachersListResetPaginationState = createAction(
  'pagination/TEACHERS_LIST_RESET_PAGINATION_STATE',
)();
