import { createSelector } from 'reselect';
import { getState } from '../../store/redux/state';

export const getServiceWorkerInitialized = createSelector(
  [getState],
  (state) => state.sw.serviceWorkerInitialized,
);

export const getServiceWorkerUpdated = createSelector(
  [getState],
  (state) => state.sw.serviceWorkerUpdated,
);
