import React, { Suspense } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Home from './home/Home';
import { swInit, swUpdate } from './component/serviceworker/redux/action';
import LazyLoadingIndicator from './component/loading/LazyLoadingIndicator';

// Common
const SignedIn = React.lazy(() => import('./auth/SignedIn'));
const Login = React.lazy(() => import('./auth/Login'));
const UserProfile = React.lazy(() => import('./auth/UserProfile'));
const Logout = React.lazy(() => import('./auth/Logout'));
const Module = React.lazy(() => import('./boAdmin/module/Module'));

const DashboardPromotion = React.lazy(() => import('./boAdmin/dashboard/DashboardPromotion'));

// Super Admin Space
const Admin = React.lazy(() => import('./boSuperAdmin/Admin'));
const AdminDeleteClaims = React.lazy(() => import('./boSuperAdmin/AdminDeleteClaims'));
const AdminAddClaims = React.lazy(() => import('./boSuperAdmin/AdminAddClaims'));
const UserInfo = React.lazy(() => import('./boSuperAdmin/UserInfo'));
const CreateUser = React.lazy(() => import('./boSuperAdmin/CreateUser'));
const DeleteUser = React.lazy(() => import('./boSuperAdmin/DeleteUser'));

// Admin Space
const ModuleCreation = React.lazy(() => import('./boAdmin/module/ModuleCreation'));
const ModuleEdit = React.lazy(() => import('./boAdmin/module/ModuleEdit'));
const ModuleDelete = React.lazy(() => import('./boAdmin/module/ModuleDelete'));

const ScolarYear = React.lazy(() => import('./boAdmin/scolarYear/ScolarYear'));

const Promotion = React.lazy(() => import('./boAdmin/promotion/Promotion'));
const PromotionCreate = React.lazy(() => import('./boAdmin/promotion/PromotionCreate'));
const PromotionEdit = React.lazy(() => import('./boAdmin/promotion/PromotionEdit'));

const Teacher = React.lazy(() => import('./boAdmin/teacher/Teacher'));
const TeacherCreate = React.lazy(() => import('./boAdmin/teacher/TeacherCreate'));
const TeacherEdit = React.lazy(() => import('./boAdmin/teacher/TeacherEdit'));

const boStudent = React.lazy(() => import('./boAdmin/student/Student'));
const StudentEdit = React.lazy(() => import('./boAdmin/student/StudentEdit'));
const StudentCreate = React.lazy(() => import('./boAdmin/student/StudentCreate'));

const ModuleLabel = React.lazy(() => import('./boAdmin/moduleLabel/ModuleLabel'));
const ModuleLabelCreate = React.lazy(() => import('./boAdmin/moduleLabel/ModuleLabelCreate'));
const ModuleLabelDelete = React.lazy(() => import('./boAdmin/moduleLabel/ModuleLabelDelete'));

const PedagogicalTeachingUnitLabel = React.lazy(
  () => import('./boAdmin/pedagogicalTeachingUnitLabel/PedagogicalTeachingUnitLabel'),
);
const PedagogicalTeachingUnitLabelCreate = React.lazy(
  () => import('./boAdmin/pedagogicalTeachingUnitLabel/PedagogicalTeachingUnitLabelCreate'),
);
const PedagogicalTeachingUnitLabelDelete = React.lazy(
  () => import('./boAdmin/pedagogicalTeachingUnitLabel/PedagogicalTeachingUnitLabelDelete'),
);

const UnifiedModuleLabel = React.lazy(
  () => import('./boAdmin/unifiedModuleLabel/UnifiedModuleLabel'),
);
const UnifiedModuleLabelCreate = React.lazy(
  () => import('./boAdmin/unifiedModuleLabel/UnifiedModuleLabelCreate'),
);
const UnifiedModuleLabelDelete = React.lazy(
  () => import('./boAdmin/unifiedModuleLabel/UnifiedModuleLabelDelete'),
);

const boSubmission = React.lazy(() => import('./boAdmin/submission/Submission'));

const Assessment = React.lazy(() => import('./boAdmin/assessment/Assessment'));
const AssessmentCreate = React.lazy(() => import('./boAdmin/assessment/AssessmentCreate'));
const AssessmentEdit = React.lazy(() => import('./boAdmin/assessment/AssessmentEdit'));
const AssessmentDelete = React.lazy(() => import('./boAdmin/assessment/AssessmentDelete'));
const AssessmentGrade = React.lazy(() => import('./boAdmin/assessment/AssessmentGrade'));
const AssessmentComment = React.lazy(() => import('./boAdmin/assessment/AssessmentComment'));

const boStudentGrade = React.lazy(() => import('./boAdmin/student/grade/StudentGrade'));
const StudentGradeDetail = React.lazy(() => import('./boAdmin/student/grade/StudentGradeDetail'));
const StudentCareerCreate = React.lazy(
  () => import('./boAdmin/student/career/StudentCareerCreate'),
);
const StudentCareerEdit = React.lazy(() => import('./boAdmin/student/career/StudentCareerEdit'));
const StudentCareerDelete = React.lazy(
  () => import('./boAdmin/student/career/StudentCareerDelete'),
);
const StudentReportMonitoring = React.lazy(
  () => import('./boAdmin/student/report/StudentReportMonitoring'),
);
const StudentReportMonitoringAdd = React.lazy(
  () => import('./boAdmin/student/report/StudentReportMonitoringAdd'),
);

const AppSetting = React.lazy(() => import('./boAdmin/configuration/AppSetting'));
const Importer = React.lazy(() => import('./boAdmin/importer/Importer'));
const ImporterModuleMonitoring = React.lazy(
  () => import('./boAdmin/importer/ImporterModuleMonitoring'),
);
const ImporterStudentMonitoring = React.lazy(
  () => import('./boAdmin/importer/ImporterStudentMonitoring'),
);
const Exporter = React.lazy(() => import('./boAdmin/exporter/Exporter'));

// Referent Space
const ModuleReferent = React.lazy(() => import('./boAdmin/module/ModuleReferent'));
const ReferentSubmission = React.lazy(() => import('./boAdmin/submission/Submission'));

// Student Space
const foStudent = React.lazy(() => import('./foStudent/assessment/Student'));
const foStudentGrade = React.lazy(() => import('./foStudent/assessment/StudentGradeDetail'));

const SubmissionForm = React.lazy(() => import('./foStudent/submission/SubmissionForm'));
const SubmissionInvitation = React.lazy(
  () => import('./foStudent/submission/SubmissionInvitation'),
);
const SubmissionList = React.lazy(() => import('./foStudent/submission/SubmissionList'));
const SubmissionSearch = React.lazy(() => import('./foStudent/submission/SubmissionSearch'));

export default function Router() {
  const dispatch = useDispatch();
  if (process.env.NODE_ENV === 'production') {
    serviceWorkerRegistration.register({
      onSuccess: () => dispatch(swInit({})),
      onUpdate: (reg) => dispatch(swUpdate(reg)),
    });
  } else {
    serviceWorkerRegistration.unregister();
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<LazyLoadingIndicator />}>
        <Switch>
          <Route exact path="/" component={Home} />

          {/* Common */}
          <Route exact path="/signedIn" component={SignedIn} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/profile" component={UserProfile} />
          <Route exact path="/logout" component={Logout} />

          <Redirect exact from="/admin" to="/admin/form/list" />
          <Route path="/admin/form/list" component={Module} />

          <Redirect exact from="/superAdmin" to="/superAdmin/adminList" />
          <Route path="/superAdmin/adminList" component={Admin} />

          <Route path="/admin/dashboard/promotion" component={DashboardPromotion} />

          {/* Super admin */}

          <Route
            path="/superAdmin/deleteClaims/:adminId/:displayName"
            component={AdminDeleteClaims}
          />
          <Route path="/superAdmin/addClaims/" component={AdminAddClaims} />
          <Route path="/superAdmin/userInfo" component={UserInfo} />
          <Route path="/superAdmin/createUser" component={CreateUser} />
          <Route path="/superAdmin/deleteUser" component={DeleteUser} />

          {/* Admin space */}

          <Route path="/admin/survey/creation/:currentScolarYear" component={ModuleCreation} />
          <Route path="/admin/survey/edit/:courseId" component={ModuleEdit} />
          <Route path="/admin/survey/delete/:courseId" component={ModuleDelete} />

          <Route path="/admin/scolaryears/list" component={ScolarYear} />

          <Route path="/admin/promos/list" component={Promotion} />
          <Route path="/admin/promos/create/:scolarYear" component={PromotionCreate} />
          <Route path="/admin/promos/edit/:scolarYear/:promotionId" component={PromotionEdit} />

          <Route path="/admin/teachers/list" component={Teacher} />
          <Route path="/admin/teachers/create/" component={TeacherCreate} />
          <Route path="/admin/teachers/edit/:teacherId/:displayName" component={TeacherEdit} />

          <Route path="/admin/students/list" component={boStudent} />
          <Route path="/admin/students/create/:scolarYear" component={StudentCreate} />
          <Route path="/admin/students/edit/:studentId/:displayName" component={StudentEdit} />

          <Route path="/admin/courses/list" component={ModuleLabel} />
          <Route path="/admin/courses/create/" component={ModuleLabelCreate} />
          <Route path="/admin/courses/delete/:label" component={ModuleLabelDelete} />

          <Route
            path="/admin/pedagogicalTeachingUnits/list"
            component={PedagogicalTeachingUnitLabel}
          />
          <Route
            path="/admin/pedagogicalTeachingUnits/create/"
            component={PedagogicalTeachingUnitLabelCreate}
          />
          <Route
            path="/admin/pedagogicalTeachingUnits/delete/:label"
            component={PedagogicalTeachingUnitLabelDelete}
          />

          <Route path="/admin/unifiedCourses/list" component={UnifiedModuleLabel} />
          <Route path="/admin/unifiedCourses/create/" component={UnifiedModuleLabelCreate} />
          <Route path="/admin/unifiedCourses/delete/:label" component={UnifiedModuleLabelDelete} />

          <Route path="/admin/form/detail/:courseId" component={boSubmission} />

          <Route path="/admin/module/detail/:courseId" component={Assessment} />
          <Route
            path="/admin/module/:courseId/:courseLabel/:coursePromo/create"
            component={AssessmentCreate}
          />
          <Route
            path="/admin/module/:courseId/:courseLabel/edit/:assessmentId"
            component={AssessmentEdit}
          />
          <Route
            path="/admin/module/:courseId/:courseLabel/delete/:assessmentId/:assessmentTitle"
            component={AssessmentDelete}
          />
          <Route
            path="/admin/module/:courseId/:courseLabel/results/edit/:assessmentId"
            component={AssessmentGrade}
          />
          <Route
            path="/admin/module/:courseId/:courseLabel/comments"
            component={AssessmentComment}
          />

          <Route path="/admin/students/detail/:studentId/:displayName" component={boStudentGrade} />
          <Route
            path="/admin/students/module/detail/:scolarYear/:promotionId/:courseId/:studentId/:displayName"
            component={StudentGradeDetail}
          />
          <Route
            path="/admin/students/career/create/:studentId/:scolarYear"
            component={StudentCareerCreate}
          />
          <Route
            path="/admin/students/career/edit/:studentId/:scolarYear/:promotionId"
            component={StudentCareerEdit}
          />
          <Route
            path="/admin/students/career/delete/:studentId/:displayName/:scolarYear/:promotionId/:promotionLabel"
            component={StudentCareerDelete}
          />
          <Route exact path="/admin/students/monitoring" component={StudentReportMonitoring} />
          <Route
            exact
            path="/admin/students/monitoring/add"
            component={StudentReportMonitoringAdd}
          />

          <Route exact path="/settings" component={AppSetting} />
          <Route exact path="/importer" component={Importer} />
          <Route exact path="/importer/modules/monitoring" component={ImporterModuleMonitoring} />
          <Route exact path="/importer/students/monitoring" component={ImporterStudentMonitoring} />
          <Route exact path="/exporter" component={Exporter} />

          {/* Referent space */}

          <Route path="/referents/form/list" component={ModuleReferent} />
          <Route path="/referents/form/detail/:courseId" component={ReferentSubmission} />

          {/* Student space */}

          <Route path="/student/grades/list" component={foStudent} />
          <Route
            path="/student/module/detail/:scolarYear/:promotionId/:courseId"
            component={foStudentGrade}
          />

          <Route path="/survey/form/:surveyId" component={SubmissionForm} />
          <Route path="/survey/invitation/:surveyId/:keyHash" component={SubmissionInvitation} />
          <Redirect exact from="/survey" to="/survey/list" />
          <Route path="/survey/list" component={SubmissionList} />
          <Route path="/survey/search" component={SubmissionSearch} />

          <Redirect path="/" to="/" />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}
